export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
    access_nivel: [1,2],
  },
  {
    title: 'Cadastros',
    icon: 'FileTextIcon',
    access_nivel: [1],
    children: [
      {
        title: 'Usuários',
        route: 'list-users',
        access_nivel: [1],
      },
    ],
  },
  {
    title: 'Processos',
    route: 'processes',
    icon: 'FileIcon',
    access_nivel: [1,2],
  },
]
